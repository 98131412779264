import Head from 'next/head';
import globalMeta from './globalMeta';

// Code is from => https://www.ohmycrawl.com/nextjs-head/

export default function SEOHead({
    title = globalMeta.siteName,
    description = globalMeta.description,
    canonicalUrl = globalMeta.siteUrl,
    //@ts-ignore
    ogType,
    ogImgUrl = globalMeta.siteLogo,
    //@ts-ignore
    structuredData
}) {
    return (
        <Head>
            {/*
        	Fundamental head elements important for SEO.
    	*/}
            <title>{title} </title>
            <meta name="description" content={description} />
            <link rel="canonical" href={canonicalUrl} />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="icon" href="/favicon.ico" />

            {/*
        	Open graph meta tags.
    	*/}
            <meta property="og:locale" content="en_US" />
            <meta name="keywords" content="summarization, GPT-3, ad-auris, Ad Auris, audio, generative AI, AI" />
            <meta property="og:title" content={globalMeta.siteName} />
            <meta property="og:site_name" content={globalMeta.siteName} />
            <meta property="og:type" content={ogType} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={ogImgUrl} />
            <meta property="og:url" content={canonicalUrl} />

            {/* Twitter Metadata */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content="@AdAuris_audio" />
            <meta name="twitter:site" content="@AdAuris_audio" />
            <meta name="twitter:title" content={globalMeta.siteName} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={ogImgUrl} />

            {/*
        	Structured data.
    	*/}
            <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: structuredData }} key="item-jsonld" />
        </Head>
    );
}
