import { ParsedUrlQuery } from 'querystring';
import { PLAY_APP_LINK } from '../constants/defaultConstants';
import { UTMInterface, UTMQueryInterface } from '../types/types';

export const getPlayAppLink = (utmCodes?: UTMQueryInterface, baseUrl?: string) => {
    let url = baseUrl || PLAY_APP_LINK;

    let source = utmCodes?.utm_source || 'creo';
    let medium = utmCodes?.utm_medium || 'mirco-app';
    let campaign = utmCodes?.utm_campaign || 'summarize';

    url = `${url}?utm_source=${source}&utm_medium=${medium}&utm_campaign=${campaign}`;

    if (utmCodes?.utm_content) {
        url = `${url}&utm_content=${utmCodes.utm_content}`;
    }

    return url;
};

export const parseUtmCodes = (query: ParsedUrlQuery): UTMInterface => {
    const { utm_source, utm_medium, utm_campaign, utm_content } = query;

    return {
        source: (utm_source as string) || null,
        medium: (utm_medium as string) || null,
        campaign: (utm_campaign as string) || null,
        content: (utm_content as string) || null
    };
};
