import React, { useEffect, useMemo, useState } from 'react';
import { Flex, Image, Tooltip, IconButton, Link, useColorModeValue, Button, Box } from '@chakra-ui/react';

import { GiNewspaper } from 'react-icons/gi';
import { useRouter } from 'next/router';

// import { getSignUpPath } from '../../helpers/demoHelpers';
import { AudioPlayerMarquee } from './AudioPlayerMarquee';
import { AudioPlayerConfig } from './AudioPlayerConfig';
import { NarrationInterface } from '../../types/types';
import { useGlobalNarrationContext } from '../../context/globalNarration';

export default function CustomAudioPlayer() {
    const { playerRef, selectedNarration } = useGlobalNarrationContext();

    const [speed, setSpeed] = useState(1);
    const router = useRouter();
    const isTextNarration = useMemo(() => !selectedNarration?.siteName, [selectedNarration]);

    const onReadStoryClicked = () => {
        // registerPosthogEvent(EVENT_NAME.clickLink, {
        //     href: selectedNarration?.sourceUrl
        // });
    };

    useEffect(() => {
        try {
            if (playerRef.current) {
                playerRef.current.audio.current.playbackRate = speed;
            }
        } catch (error: unknown) {
            // captureException(new Error('Error setting playback speed - via CustomAudioPlayer', { cause: error as Error }));
        }
    }, [speed]);

    const toggleSpeed = ({ speed, setSpeed }: any) => {
        let newSpeed = speed;
        switch (speed) {
            case 1.0:
                newSpeed = 1.25;
                break;
            case 1.25:
                newSpeed = 1.5;
                break;
            case 1.5:
                newSpeed = 2.0;
                break;
            case 2.0:
                newSpeed = 2.5;
                break;
            case 2.5:
                newSpeed = 0.5;
                break;
            case 0.5:
            default:
                newSpeed = 1.0;
        }

        // registerPosthogEvent(EVENT_NAME.speedChange, {
        //     before: speed,
        //     after: setSpeed
        // });

        // TODO: log speed
        setSpeed(newSpeed);
    };

    const articleLink = (
        <Tooltip hasArrow label="Read The Article" shouldWrapChildren mt="3">
            <Link href={selectedNarration?.sourceUrl} onClick={onReadStoryClicked} isExternal>
                <IconButton m={2} variant="outline" colorScheme="currentColor" aria-label="read article" icon={<GiNewspaper />} />{' '}
            </Link>
        </Tooltip>
    );

    const speedButton = (
        <Tooltip hasArrow label="Adjust Speed" shouldWrapChildren mt="3">
            <Button
                m="2"
                variant="outline"
                colorScheme="currentColor"
                onClick={() => {
                    toggleSpeed({ speed, setSpeed });
                }}
            >
                {speed}x
            </Button>
        </Tooltip>
    );

    return (
        <Flex
            display={'none'}
            alignItems="center"
            justifyContent="center"
            position="fixed"
            flex-direction="column"
            width="100%"
            margin-top="auto"
            bottom="0"
            right="0"
            zIndex="overlay"
            bg={useColorModeValue('white', 'gray.800')}
            borderColor={useColorModeValue('inherit', 'gray.700')}
            borderTopWidth="1px"
        >
            <Flex
                w="full"
                as="footer"
                flexDir={{ base: 'column', lg: 'row' }}
                align="center"
                justify={{ base: 'center', lg: 'space-between' }}
                px="6"
                py="1"
                bg="white"
                _dark={{
                    bg: 'gray.800'
                }}
                roundedTop="lg"
            >
                <Flex
                    flexDir="row"
                    align="center"
                    justify="center"
                    pl={{ base: '0', lg: '6' }}
                    py="1"
                    bg="white"
                    _dark={{
                        bg: 'gray.800'
                    }}
                    roundedTop="lg"
                    width={{ base: '100%', lg: '' }}
                >
                    <Image
                        mx={5}
                        borderRadius="md"
                        boxSize={{ base: '5.5vh', lg: '70px' }}
                        objectFit="cover"
                        src={selectedNarration?.image || ''}
                        alt="article-image"
                    />
                    <AudioPlayerMarquee selectedNarration={selectedNarration || ({} as NarrationInterface)} />
                    <Flex pl={1} ml={'auto'} display={{ base: 'flex', lg: 'none' }}>
                        {articleLink}
                        <Box display={{ base: 'none', '425px': 'flex', lg: 'none' }}>{speedButton}</Box>
                    </Flex>
                </Flex>
                <AudioPlayerConfig speed={speed} toggleSpeed={toggleSpeed} setSpeed={setSpeed} />
                <Flex px="8" py="4" display={{ base: 'none', lg: 'flex' }}>
                    {speedButton}
                    {articleLink}
                </Flex>
            </Flex>
        </Flex>
    );
}
