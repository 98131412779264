import LightModeLogo from '../Icons/LightModeLogo';
import { motion } from 'framer-motion';
import { Link, Icon, Text, HStack } from '@chakra-ui/react';
import { registerPosthogEvent } from '../../helpers/posthogEventHelpers';
import { EVENT_NAME } from '../../constants/posthogConstants';
import { useGlobalNarrationContext } from '../../context/globalNarration';
import { getPlayAppLink } from '../../helpers/utmHelpers';
import { HiArrowUturnRight } from 'react-icons/hi2';

export const AdAurisLogo = () => {
    const { distinctId } = useGlobalNarrationContext();

    const handleClick = () => {
        const url = getPlayAppLink({ utm_content: 'logo' });
        registerPosthogEvent(EVENT_NAME.buttonClick, distinctId, {
            type: 'playRedirectSummarizeAppLogo',
            url,
            currentLocation: window.location.href
        });
        window.open(url);
    };

    return (
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Link onClick={handleClick}>
                <HStack spacing="24px">
                    {/* Remove as we do not want AA Logo */}
                    {/* <LightModeLogo /> */}
                    <Icon as={HiArrowUturnRight} boxSize={12} color="orange.500" />
                    <Text fontSize="4xl" color="white">
                        Summarize
                    </Text>
                </HStack>
            </Link>
        </motion.div>
    );
};
