import { Box, Button, Heading } from '@chakra-ui/react';
import { JellyfishSpinner } from 'react-spinners-kit';

interface ErrorCardProps {
    setIsError: (param: boolean) => void;
}

export const ErrorCard = ({ setIsError }: ErrorCardProps) => {
    const handleTryAgain = () => {
        setIsError(false);
    };

    return (
        <>
            <Heading size="sm" textAlign={'center'}>
                An error occured. Our AI is probably busy taking over the world... again. <br />
                We recommend trying again with a different URL.
            </Heading>
            <JellyfishSpinner />
            <Button onClick={handleTryAgain}>Try again</Button>
        </>
    );
};
