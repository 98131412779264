import { Heading, Stack, Text } from '@chakra-ui/react';

export const HeaderCard = () => {
    return (
        <Stack spacing={4}>
            <Heading color={'gray.800'} lineHeight={1.1} fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }} alignSelf="center">
                Audio Summarization!
            </Heading>
            <Text color={'gray.800'} fontSize={{ base: 'sm', sm: 'md' }}>
                {`Want to listen to a summary of an article? Grab the article URL and click "Generate Audio Summary". What
                            you'll generate through our AI powered platform is a summarized version in audio form.`}
            </Text>
        </Stack>
    );
};
