import { Link, Text } from '@chakra-ui/react';
import React from 'react';
import { EVENT_NAME } from '../../constants/posthogConstants';
import { useGlobalNarrationContext } from '../../context/globalNarration';
import { registerPosthogEvent } from '../../helpers/posthogEventHelpers';
import { getPlayAppLink } from '../../helpers/utmHelpers';

export default function Footer(): JSX.Element {
    const { distinctId } = useGlobalNarrationContext();
    const handleClick = () => {
        const url = getPlayAppLink({ utm_content: 'footer-link' });
        registerPosthogEvent(EVENT_NAME.buttonClick, distinctId, {
            type: 'adAurisFooterLink',
            url,
            currentLocation: window.location.href
        });
        window.open(url);
    };

    return (
        <Text textAlign="center" fontSize="md" _dark={{ color: 'white' }} position="absolute" bottom="10px">
            Powered by{' '}
            <Link onClick={handleClick} color="#04FF00">
                &copy;Ad Auris
            </Link>
        </Text>
    );
}
