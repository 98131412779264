import { PublishDateInterface } from "../types/types";

export const formatDateFromSeconds = (publishDate: PublishDateInterface): string => {
    const dateString: string = new Date(publishDate._seconds * 1000).toDateString();
    // Cuts off the day from the string ex: Wed March 3 2022
    const slicedDate: string[] = dateString.split(' ');
    slicedDate.shift();

    if (slicedDate[1]) {
        return slicedDate.join(' ');
    } else {
        return dateString;
    }
};