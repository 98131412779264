import { PLAY_APP_LINK } from '../constants/defaultConstants';
import { APP_ENV, UrlType } from '../types/types';

export const initializeBaseAppUrl = (environmentVar?: APP_ENV): UrlType => {
    let baseAppUrl: UrlType = '';

    const appEnvironment = environmentVar || process.env.APP_ENV;

    switch (appEnvironment) {
        case APP_ENV.development:
            baseAppUrl = `https://beta-play-staging.herokuapp.com`;
            break;
        case APP_ENV.test:
            baseAppUrl = `https://beta-play-staging.herokuapp.com`;
            break;
        case APP_ENV.staging:
            baseAppUrl = `https://beta-play-staging.herokuapp.com`;
            break;
        case APP_ENV.production:
            baseAppUrl = PLAY_APP_LINK;
            break;
        default:
            baseAppUrl = PLAY_APP_LINK;
    }
    return baseAppUrl;
};

export const isGoogleDoc = (urlInput: string) => {
    let urlObj;
    try {
        urlObj = new URL(urlInput);
    } catch (e) {
        return false;
    }

    const host = urlObj.hostname.toLowerCase();
    const docHost = 'docs.google.com';

    if (host === docHost) {
        return true;
    }

    return false;
};

export const isUrl = (urlInput: string) => {
    try {
        new URL(urlInput);
    } catch (_) {
        return false;
    }

    return true;
};

export const isEbook = (url: string) => {
    return url.lastIndexOf('.epub') > 0;
};

export const isPdf = (url: string) => {
    return url.lastIndexOf('.pdf') > 0;
};

export const isMissingHttp = (url: string) => {
    return !!url && !url.includes('http://') && !url.includes('https://');
};

export const isDoi = (url: string) => {
    return url.includes('/doi') || url.includes('doi:');
};

export const isPodcastLink = (url: string) => {
    return url.includes('podcasts.ad-auris');
};

export const isAdAurisLink = (url: string) => {
    return url.includes('play.ad-auris');
};

export const getRSSFeedUrl = (uid: string) => {
    return `https://${process.env.PLAY_PODCAST_HOST_RSS_FEED}/${uid}/feed`;
};
