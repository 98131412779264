export const LANDING_PAGE_A = '/features/a';
export const LANDING_PAGE_B = '/features/b';
export const REGISTER_EVENT_API_URL = '/api/v1/register-event';

export enum EVENT_NAME {
    betaAccessAttempt = 'betaAccessAttempt',
    buttonClick = 'buttonClick',
    textInput = 'textInput',
    chromeSetUpSuccess = 'chromeSetUpSuccess',
    menuClick = 'menuClick',
    menuOptionClick = 'menuOptionClick',
    speedChange = 'speedChange',
    pageLoad = 'pageLoad',
    signup = 'signUp',
    signin = 'signIn',
    logOut = 'logOut',
    requestNarrationCreation = 'requestNarrationCreation',
    attemptNarrationCreation = 'attemptNarrationCreation',
    deleteNarration = 'deleteNarration',
    flagNarration = 'flagNarration',
    gotoNextNarration = 'gotoNextNarration',
    gotoPreviousNarration = 'gotoPreviousNarration',
    play = 'play',
    pause = 'pause',
    seeked = 'seeked',
    timeUpdate = 'timeUpdate',
    ended = 'ended',
    liked = 'liked',
    beforeUnload = 'beforeUnload',
    goBackSeconds = 'goBackSeconds',
    goForwardSeconds = 'goForwardSeconds',
    clickLink = 'clickLink',
    selectNarration = 'selectNarration',
    filterNarration = 'filterNarration',
    session_start = 'startSession',
    chromeExtOpen = 'chromeExtOpen',
    secondsListenedUpdate = 'secondsListenedUpdate',
    percentage = 'percentage',
    shareNarration = 'shareNarration',
    lockedFeatureAttempt = 'lockedFeatureAttempt',
    subscribeToPaymentPlan = 'subscribeToPaymentPlan',
    cancelPaymentPlan = 'cancelPaymentPlan',
    setSubscriptionTier = 'setSubscriptionTier',
    setSubscriptionChange = 'setSubscriptionChange',
    addPrenarratedNarration = 'addPrenarratedNarration',
    clickGetChromeExtension = 'clickGetChromeExtension',
    clickTryForFree = 'clickTryForFree',
    clickSignIn = 'clickSignIn'
}
