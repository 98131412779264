import React, { useEffect, useMemo, useState } from 'react';
import { Link, Image, IconButton, SimpleGrid, Box, Flex, AspectRatio, useColorModeValue, useToast, Tooltip, ToastId } from '@chakra-ui/react';
import { BsFillPlayCircleFill, BsFillPauseCircleFill } from 'react-icons/bs';
import { motion } from 'framer-motion';

import { NarrationInterface } from '../../types/types';
import ShareLinkButton from '../Buttons/ShareLinkButton';
import { formatDateFromSeconds } from '../../helpers/dateHelpers';
import { useGlobalNarrationContext } from '../../context/globalNarration';

interface libNarCardProps {
    narration: NarrationInterface;
}

export default function YourLibraryNarrationCard({ narration }: libNarCardProps) {
    const { playPause, setSelectedNarration, setOnEnded, setPlaying } = useGlobalNarrationContext();
    const { siteName, title, publishDate, image } = narration;
    const [selected, setSelected] = useState(false);
    const formattedPublishDate = useMemo(() => (publishDate ? formatDateFromSeconds(publishDate) : ''), [publishDate]);

    useEffect(() => {
        const eventFunc = () => (_: any) => {
            setSelected(false);
            setPlaying(false);
        };
        setOnEnded(eventFunc);
    }, []);

    const handlePlayPauseClicked = () => {
        playPause();
        setSelected(!selected);
        setSelectedNarration(narration);
    };

    return (
        <Box w="100%" boxShadow="lg" mt="4" mb="4" borderRadius="lg" bg={useColorModeValue('white', 'gray.900')}>
            <Flex w="100%" h={{ base: '16vw', sm: '7vw' }} minH={'64px'} alignItems={{ sm: 'center' }} justifyContent={{ sm: 'flex-start' }}>
                <Box position={'relative'}>
                    <AspectRatio w={{ base: `${16 * (16 / 9)}vw`, sm: `${7 * (16 / 9)}vw` }} ratio={16 / 9} minH={'64px'}>
                        <Image
                            minH={'64px'}
                            maxH={{ base: '16vw', sm: '7vw' }}
                            borderRadius="0.375rem 0 0 0.375rem"
                            objectFit="cover"
                            src={image || ''}
                            alt="article image"
                        />
                    </AspectRatio>
                </Box>
                <Flex alignItems="center">
                    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                        <IconButton
                            variant="ghost"
                            isRound
                            fontSize={{ base: '8vw', sm: '3.5vw' }}
                            h="auto"
                            minW="unset"
                            m="2vw"
                            size="lg"
                            data-cy={`narration-play-button`}
                            color="brand.500"
                            icon={selected ? <BsFillPauseCircleFill /> : <BsFillPlayCircleFill />}
                            aria-label="play audio"
                            data-testid="library-card-play-button"
                            onClick={handlePlayPauseClicked}
                        />
                    </motion.div>
                </Flex>

                <Box display="flex" alignItems="center">
                    <SimpleGrid columns={1} row={3}>
                        <Tooltip hasArrow label={siteName}>
                            <Box
                                as="h4"
                                fontWeight="semibold"
                                fontSize="0.8em"
                                lineHeight="tight"
                                overflow="hidden"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                            >
                                {siteName}
                            </Box>
                        </Tooltip>

                        <Tooltip hasArrow label={title} shouldWrapChildren>
                            <Box as={'p'} fontSize={'0.9em'} lineHeight="tight" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                                {title}
                            </Box>
                        </Tooltip>

                        <Box
                            color="gray.500"
                            fontWeight="semibold"
                            letterSpacing="wide"
                            fontSize={'0.7em'}
                            textTransform="uppercase"
                            lineHeight="tight"
                        >
                            {formattedPublishDate}
                        </Box>
                    </SimpleGrid>
                </Box>

                <Flex
                    alignItems="center"
                    justifyContent={'center'}
                    direction={{ base: 'column', sm: 'row' }}
                    ml="auto"
                    pl={1}
                    mr={{ base: 1, md: 2 }}
                    gap={{ base: '8px', sm: 2 }}
                >
                    <ShareLinkButton narration={narration} />
                </Flex>
            </Flex>
        </Box>
    );
}
