// preset json samples here=> https://github.com/matteobruni/tsparticles/tree/main/websites/particles.js.org/presets

// other sample generator: https://particles.js.org/samples/index.html#parallax
export const TsParticlesConfigOptions = {
    spiderWebMouse: {
        fullScreen: {
            enable: true,
            zIndex: -1
        },
        background: {
            color: '#00000',
            image: "url('https://t4.ftcdn.net/jpg/02/94/54/19/360_F_294541954_zq3709LLBMgFpl3umlerYMdJcQzpMS9C.jpg')",
            position: '50% 50%',
            repeat: 'no-repeat',
            size: 'cover'
        },
        fpsLimit: 120,
        interactivity: {
            events: {
                onClick: {
                    enable: false,
                    mode: 'push'
                },
                onHover: {
                    enable: true,
                    mode: 'repulse'
                },
                resize: true
            },
            modes: {
                push: {
                    quantity: 4
                },
                repulse: {
                    distance: 200,
                    duration: 0.4
                }
            }
        },
        particles: {
            color: {
                value: '#ffffff'
            },
            links: {
                color: '#ffffff',
                distance: 150,
                enable: true,
                opacity: 1,
                width: 1
            },
            collisions: {
                enable: true
            },
            move: {
                direction: 'none',
                enable: true,
                outModes: {
                    default: 'bounce'
                },
                random: false,
                speed: 1,
                straight: false
            },
            number: {
                density: {
                    enable: true,
                    area: 800
                },
                value: 80
            },
            opacity: {
                value: 0.5
            },
            shape: {
                type: 'circle'
            },
            size: {
                value: { min: 1, max: 5 }
            }
        },
        detectRetina: true
    },
    confetti: {
        background: {
            color: {
                value: '#000000'
            }
        },
        fullScreen: {
            enable: true,
            zIndex: -1
        },
        particles: {
            color: {
                value: ['#1E00FF', '#FF0061', '#E1FF00', '#00FF9E'],
                animation: {
                    enable: true,
                    speed: 10
                }
            },
            move: {
                decay: 0.1,
                direction: 'top',
                enable: true,
                gravity: {
                    acceleration: 0,
                    enable: true,
                    maxSpeed: 10
                },
                outModes: {
                    top: 'none',
                    default: 'destroy'
                },
                speed: {
                    min: 50,
                    max: 150
                }
            },
            number: {
                value: 0,
                limit: 300
            },
            opacity: {
                value: 1,
                animation: {
                    enable: false,
                    startValue: 'max',
                    destroy: 'min',
                    speed: 0.3,
                    sync: true
                }
            },
            rotate: {
                value: {
                    min: 0,
                    max: 360
                },
                direction: 'random',
                move: true,
                animation: {
                    enable: true,
                    speed: 60
                }
            },
            tilt: {
                direction: 'random',
                enable: true,
                move: true,
                value: {
                    min: 0,
                    max: 360
                },
                animation: {
                    enable: true,
                    speed: 60
                }
            },
            shape: {
                type: ['circle', 'square', 'polygon'],
                options: {
                    polygon: [
                        {
                            sides: 5
                        },
                        {
                            sides: 6
                        }
                    ]
                }
            },
            size: {
                value: 3
            },
            roll: {
                darken: {
                    enable: true,
                    value: 30
                },
                enlighten: {
                    enable: true,
                    value: 30
                },
                enable: true,
                speed: {
                    min: 15,
                    max: 25
                }
            },
            wobble: {
                distance: 30,
                enable: true,
                move: true,
                speed: {
                    min: -15,
                    max: 15
                }
            }
        },
        emitters: {
            position: {
                x: 50,
                y: 100
            },
            size: {
                width: 0,
                height: 0
            },
            rate: {
                quantity: 10,
                delay: 0.1
            }
        }
    },
    adAurisLogoSpace: {
        fullScreen: {
            enable: true,
            zIndex: 1
        },
        particles: {
            number: {
                value: 160,
                density: {
                    enable: true,
                    value_area: 800
                }
            },
            color: {
                value: '#ffffff'
            },
            shape: {
                type: 'circle'
            },
            opacity: {
                value: 1,
                random: true,
                anim: {
                    enable: true,
                    speed: 1,
                    opacity_min: 0,
                    sync: false
                }
            },
            size: {
                value: 3,
                random: true,
                anim: {
                    enable: false,
                    speed: 4,
                    size_min: 0.3,
                    sync: false
                }
            },
            line_linked: {
                enable: false,
                distance: 150,
                color: '#ffffff',
                opacity: 0.4,
                width: 1
            },
            move: {
                enable: true,
                speed: 1,
                direction: 'none',
                random: true,
                straight: false,
                out_mode: 'out',
                bounce: false,
                attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 600
                }
            }
        },
        interactivity: {
            events: {
                onhover: {
                    enable: true,
                    mode: 'bubble'
                },
                onclick: {
                    enable: true,
                    mode: 'repulse'
                },
                resize: true
            },
            modes: {
                grab: {
                    distance: 400,
                    line_linked: {
                        opacity: 1
                    }
                },
                bubble: {
                    distance: 250,
                    size: 0,
                    duration: 2,
                    opacity: 0,
                    speed: 3
                },
                repulse: {
                    distance: 400,
                    duration: 0.4
                },
                push: {
                    particles_nb: 4
                },
                remove: {
                    particles_nb: 2
                }
            }
        },
        retina_detect: true,
        background: {
            color: '#232741',
            image: "url('https://storage.googleapis.com/ad-auris-mvp-bucket/internal-tool/resources/Ad%20Auris%20Logo%20Assets-01.png')",
            position: '50% 50%',
            repeat: 'no-repeat',
            size: '20%'
        }
    },
    flyingInSpaceAdAurisLogo: {
        fullScreen: {
            enable: true
        },
        fpsLimit: 120,
        particles: {
            groups: {
                z5000: {
                    number: {
                        value: 70
                    },
                    zIndex: {
                        value: 50
                    }
                },
                z7500: {
                    number: {
                        value: 30
                    },
                    zIndex: {
                        value: 75
                    }
                },
                z2500: {
                    number: {
                        value: 50
                    },
                    zIndex: {
                        value: 25
                    }
                },
                z1000: {
                    number: {
                        value: 40
                    },
                    zIndex: {
                        value: 10
                    }
                }
            },
            number: {
                value: 200
            },
            color: {
                value: '#fff'
            },
            shape: {
                type: 'circle'
            },
            opacity: {
                value: 1
            },
            size: {
                value: 3
            },
            move: {
                angle: {
                    value: 10,
                    offset: 0
                },
                enable: true,
                speed: 5,
                direction: 'right',
                outModes: 'out'
            },
            zIndex: {
                value: 5,
                opacityRate: 0.5
            }
        },
        interactivity: {
            events: {
                onClick: {
                    enable: true,
                    mode: 'push'
                },
                resize: true
            },
            modes: {
                push: {
                    quantity: 4,
                    groups: ['z5000', 'z7500', 'z2500', 'z1000']
                }
            }
        },
        background: {
            color: '#000000'
        },
        emitters: {
            position: {
                y: 55,
                x: -5
            },
            rate: {
                delay: 7,
                quantity: 1
            },
            size: {
                width: 0,
                height: 0
            },
            particles: {
                shape: {
                    type: 'images',
                    options: {
                        images: {
                            src: 'https://storage.googleapis.com/ad-auris-mvp-bucket/internal-tool/resources/Ad%20Auris%20Logo%20Assets-05.png',
                            width: 500,
                            height: 634
                        }
                    }
                },
                size: {
                    value: 40
                },
                move: {
                    speed: 10,
                    outModes: {
                        default: 'none',
                        right: 'destroy'
                    },
                    straight: true
                },
                zIndex: {
                    value: 0
                },
                rotate: {
                    value: {
                        min: 0,
                        max: 360
                    },
                    animation: {
                        enable: true,
                        speed: 10,
                        sync: true
                    }
                }
            }
        }
    },
    flyingInSpaceGriffin: {
        fullScreen: {
            enable: true
        },
        fpsLimit: 120,
        particles: {
            groups: {
                z5000: {
                    number: {
                        value: 70
                    },
                    zIndex: {
                        value: 50
                    }
                },
                z7500: {
                    number: {
                        value: 30
                    },
                    zIndex: {
                        value: 75
                    }
                },
                z2500: {
                    number: {
                        value: 50
                    },
                    zIndex: {
                        value: 25
                    }
                },
                z1000: {
                    number: {
                        value: 40
                    },
                    zIndex: {
                        value: 10
                    }
                }
            },
            number: {
                value: 200
            },
            color: {
                value: '#fff'
            },
            shape: {
                type: 'circle'
            },
            opacity: {
                value: 1
            },
            size: {
                value: 3
            },
            move: {
                angle: {
                    value: 10,
                    offset: 0
                },
                enable: true,
                speed: 5,
                direction: 'right',
                outModes: 'out'
            },
            zIndex: {
                value: 5,
                opacityRate: 0.5
            }
        },
        interactivity: {
            events: {
                onClick: {
                    enable: true,
                    mode: 'push'
                },
                resize: true
            },
            modes: {
                push: {
                    quantity: 4,
                    groups: ['z5000', 'z7500', 'z2500', 'z1000']
                }
            }
        },
        background: {
            color: '#000000'
        },
        emitters: {
            position: {
                y: 55,
                x: -5
            },
            rate: {
                delay: 7,
                quantity: 1
            },
            size: {
                width: 0,
                height: 0
            },
            particles: {
                shape: {
                    type: 'images',
                    options: {
                        images: {
                            src: 'https://storage.googleapis.com/ad-auris-mvp-bucket/internal-tool/resources/Memes%20(3).jpg',
                            width: 500,
                            height: 634
                        }
                    }
                },
                size: {
                    value: 40
                },
                move: {
                    speed: 10,
                    outModes: {
                        default: 'none',
                        right: 'destroy'
                    },
                    straight: true
                },
                zIndex: {
                    value: 0
                },
                rotate: {
                    value: {
                        min: 0,
                        max: 360
                    },
                    animation: {
                        enable: true,
                        speed: 10,
                        sync: true
                    }
                }
            }
        }
    },
    parallax: {
        fullScreen: {
            enable: true,
            zIndex: -1
        },
        fpsLimit: 120,
        particles: {
            number: {
                value: 100,
                density: {
                    enable: true,
                    value_area: 800
                }
            },
            color: {
                value: '#ffffff'
            },
            shape: {
                type: 'circle'
            },
            opacity: {
                value: 0.5,
                random: true,
                anim: {
                    enable: true,
                    speed: 3,
                    opacity_min: 0.1,
                    sync: false
                }
            },
            size: {
                value: 10,
                random: true,
                anim: {
                    enable: true,
                    speed: 20,
                    size_min: 0.1,
                    sync: false
                }
            },
            line_linked: {
                enable: true,
                distance: 150,
                color: '#ffffff',
                opacity: 0.4,
                width: 1
            },
            move: {
                enable: true,
                speed: 2,
                direction: 'none',
                random: false,
                straight: false,
                out_mode: 'out',
                attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200
                }
            }
        },
        interactivity: {
            events: {
                onhover: {
                    enable: true,
                    mode: 'grab',
                    parallax: {
                        enable: true,
                        smooth: 10,
                        force: 60
                    }
                },
                onclick: {
                    enable: true,
                    mode: 'push'
                },
                resize: true
            },
            modes: {
                grab: {
                    distance: 400,
                    line_linked: {
                        opacity: 1
                    }
                },
                bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 0.8,
                    speed: 3
                },
                repulse: {
                    distance: 200
                },
                push: {
                    particles_nb: 4
                },
                remove: {
                    particles_nb: 2
                }
            }
        },
        retina_detect: true,
        background: {
            color: '#7ccb37',
            image: 'https://images.unsplash.com/photo-1579546929518-9e396f3cc809?ixlib=rb-4.0.3&w=1080&fit=max&q=80&fm=jpg&crop=entropy&cs=tinysrgb',
            position: '50% 50%',
            repeat: 'no-repeat',
            size: 'cover'
        }
    }
};
