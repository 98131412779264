import { Button, Fade, SimpleGrid } from '@chakra-ui/react';
import { EVENT_NAME } from '../../constants/posthogConstants';
import { useGlobalNarrationContext } from '../../context/globalNarration';
import { registerPosthogEvent } from '../../helpers/posthogEventHelpers';
import { getPlayAppLink } from '../../helpers/utmHelpers';
import { NarrationInterface } from '../../types/types';
import YourLibraryNarrationCard from './NarrationCard';

interface SuccessfulNarrationViewProps {
    narration: NarrationInterface;
}

export const SuccessfulNarrationView = ({ narration }: SuccessfulNarrationViewProps) => {
    const { setSelectedNarration, distinctId } = useGlobalNarrationContext();

    const handleResetNarration = () => {
        registerPosthogEvent(EVENT_NAME.buttonClick, distinctId, {
            type: 'summarizeNewUrl',
            currentLocation: window.location.href
        });
        setSelectedNarration(null);
    };

    const handleListenToFullArticle = () => {
        const url = getPlayAppLink({ utm_content: 'listen-to-full-url-button' });
        registerPosthogEvent(EVENT_NAME.buttonClick, distinctId, {
            type: 'adAurisLinkListenToFullArticle',
            url,
            currentLocation: window.location.href
        });

        window.open(url);
    };

    return (
        <Fade in={true} style={{ width: '100%' }}>
            <YourLibraryNarrationCard narration={narration} />
            <SimpleGrid columns={{ base: 1, md: 2 }} gap={4} w="100%">
                <Button
                    w="full"
                    bgColor="#82b401"
                    _hover={{
                        bgGradient: 'linear(to-r, red.400,pink.400)',
                        boxShadow: 'xl'
                    }}
                    color="white"
                    onClick={handleListenToFullArticle}
                >
                    Listen To Full Article
                </Button>
                <Button onClick={handleResetNarration}>Input New URL</Button>
            </SimpleGrid>
        </Fade>
    );
};
