import { SimpleGrid, Text } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import { NarrationInterface } from '../../types/types';

interface AudioPlayerMarqueeProps {
    selectedNarration: NarrationInterface;
}

export const AudioPlayerMarquee = ({ selectedNarration }: AudioPlayerMarqueeProps) => {
    const [articleTitle, setArticleTitle] = useState(selectedNarration.title);
    const [publicationName, setPublicationName] = useState(selectedNarration.siteName);
    const [isMarqueePub, setIsMarqueePub] = useState(false);
    const [isMarqueeTitle, setIsMarqueeTitle] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const articleTitleRef = useRef<HTMLDivElement>(null);
    const publisherNameRef = useRef<HTMLDivElement>(null);

    const checkPublicationNameSize = () => {
        if (publisherNameRef.current && containerRef.current && publisherNameRef.current.scrollWidth > containerRef.current.clientWidth) {
            setIsMarqueePub(true);
        } else {
            setIsMarqueePub(false);
        }
    };

    const checkTitleSize = () => {
        if (articleTitleRef.current && containerRef.current && articleTitleRef.current.scrollWidth > containerRef.current.clientWidth) {
            setIsMarqueeTitle(true);
        } else {
            setIsMarqueeTitle(false);
        }
    };

    const checkBothSizes = () => {
        try {
            checkPublicationNameSize();
            checkTitleSize();
        } catch (error: unknown) {
            // captureException(new Error('Error with marquee refs sizing - via AudioPlayerMarquee', { cause: error as Error }));
        }
    };

    useEffect(() => {
        try {
            window.addEventListener('resize', checkBothSizes);
        } catch (error: unknown) {
            // captureException(new Error('Error setting window resize listener - via AudioPlayerMarquee', { cause: error as Error }));
        }

        return () => {
            try {
                window.removeEventListener('resize', checkBothSizes);
            } catch (error: unknown) {
                // captureException(new Error('Error removing window resize listener - via AudioPlayerMarquee', { cause: error as Error }));
            }
        };
    }, []);

    useEffect(() => {
        setArticleTitle(selectedNarration.title);
        setPublicationName(selectedNarration.siteName);
    }, [selectedNarration]);

    useEffect(() => {
        checkPublicationNameSize();
    }, [publicationName]);

    useEffect(() => {
        checkTitleSize();
    }, [articleTitle]);

    return (
        <SimpleGrid columns={1} ref={containerRef} data-cy={'audio-player-marquee'}>
            {isMarqueePub ? (
                <Marquee delay={2} gradient={false} pauseOnHover>
                    <Text as="h4" fontWeight={'semibold'} ref={publisherNameRef}>
                        {publicationName}&nbsp;&nbsp;&nbsp;
                    </Text>
                </Marquee>
            ) : (
                <Text as="h4" fontWeight={'semibold'} whiteSpace="nowrap" overflow={'hidden'} textOverflow={'ellipsis'} ref={publisherNameRef}>
                    {publicationName}
                </Text>
            )}
            {isMarqueeTitle ? (
                <Marquee delay={2} gradient={false} pauseOnHover>
                    <Text as="p" ref={articleTitleRef}>
                        {articleTitle}&nbsp;&nbsp;&nbsp;
                    </Text>
                </Marquee>
            ) : (
                <Text as="p" whiteSpace="nowrap" overflow={'hidden'} textOverflow={'ellipsis'} ref={articleTitleRef}>
                    {articleTitle}
                </Text>
            )}
        </SimpleGrid>
    );
};
