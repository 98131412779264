import { Box, Stack, Input, Button, FormControl } from '@chakra-ui/react';
import { useState } from 'react';
import { NarrationUrlErrorMessage } from '../../../components/Cards/Error/NarrationUrlErrorMessage';
import { DEFAULT_LIBRARY_NARRATION } from '../../../constants/defaultConstants';
import { useGlobalNarrationContext } from '../../../context/globalNarration';
import { checkIsError, createSummaryNarration, createUrlNarration, getNarrationById } from '../../../helpers/narrationHelpers';
import { FirestoreDocumentIdType, NarrationErrorObjInterface } from '../../../types/types';
import { captureException } from '@sentry/nextjs';
import { registerPosthogEvent } from '../../../helpers/posthogEventHelpers';
import { EVENT_NAME } from '../../../constants/posthogConstants';
import { formatSentryError } from '../../../helpers/sentryHelpers';
import { getGlobalUid } from '../../../helpers/accountHelpers';

interface GenerateSummarization {
    setIsLoading: (param: boolean) => void;
    setIsError: (param: boolean) => void;
}

export default function GenerateSummarization({ setIsLoading, setIsError }: GenerateSummarization) {
    const { setSelectedNarration, distinctId } = useGlobalNarrationContext();
    const [articleUrl, setArticleUrl] = useState('');
    const [errObj, setErrObj] = useState<NarrationErrorObjInterface | null>(null);

    const attemptNarration = async () => {
        setIsLoading(true);
        const uid = getGlobalUid();
        const payload = {
            url: articleUrl,
            id: uid,
            user: { uid },
            narrationMethod: 'Creo',
            music: '',
            narrationType: 'articleUrlSummary',
            titleVoice: 'US Male'
        };
        registerPosthogEvent(EVENT_NAME.attemptNarrationCreation, distinctId, {
            url: payload.url,
            narrationMethod: payload.narrationMethod,
            narrationType: payload.narrationType,
            currentLocation: window.location.href
        });
        const newNarrationId = await createSummaryNarration(payload);
        return newNarrationId;
    };

    const handleGenerate = async () => {
        try {
            const newNarrationId = await attemptNarration();
            const newNarration = await getNarrationById(newNarrationId);

            setSelectedNarration(newNarration);

            registerPosthogEvent(EVENT_NAME.requestNarrationCreation, distinctId, {
                url: articleUrl,
                narrationMethod: 'Creo',
                narrationType: 'articleUrlSummary',
                narrationSuccess: true,
                currentLocation: window.location.href
            });
        } catch (err) {
            // TODO make sentry call more descriptive
            const { message, cause } = formatSentryError('Error creating new summary narration', 'handleGenerate', err);
            captureException(new Error(message, cause));
            registerPosthogEvent(EVENT_NAME.requestNarrationCreation, distinctId, {
                url: articleUrl,
                narrationMethod: 'Creo',
                narrationType: 'articleUrlSummary',
                narrationSuccess: false,
                currentLocation: window.location.href
            });
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e: any) => {
        setArticleUrl(e.currentTarget.value);
        const errObj = checkIsError(e.currentTarget.value, distinctId);
        console.log('errObj', errObj);
        setErrObj(errObj);
    };

    return (
        <>
            <FormControl mt={10} w="100%" isInvalid={!!errObj}>
                <Stack spacing={4}>
                    <Input
                        placeholder="example: https://www.bbc.com/news/world-us-canada-63476147"
                        bg={'gray.100'}
                        border={0}
                        color={'gray.500'}
                        _placeholder={{
                            color: 'gray.500'
                        }}
                        onChange={handleInputChange}
                    />
                </Stack>
                {errObj && <NarrationUrlErrorMessage errObj={errObj} />}
                <Button
                    fontFamily={'heading'}
                    mt={8}
                    w={'full'}
                    bgColor="#82b401"
                    color={'white'}
                    _hover={{
                        bgGradient: 'linear(to-r, red.400,pink.400)',
                        boxShadow: 'xl'
                    }}
                    onClick={handleGenerate}
                    isDisabled={!!errObj || !articleUrl}
                >
                    Generate Audio Summary
                </Button>
            </FormControl>
        </>
    );
}
