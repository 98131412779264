import { captureException } from '@sentry/nextjs';
import { appVersion } from '../constants/appMetadataConstants';
import { EVENT_NAME, REGISTER_EVENT_API_URL } from '../constants/posthogConstants';
import { getGlobalUid } from './accountHelpers';
import { formatSentryError } from './sentryHelpers';

export const registerPosthogEvent = async (event: EVENT_NAME, distinctId: string, properties?: {}): Promise<boolean> => {
    try {
        let payload;

        const uid = getGlobalUid();

        const payloadConst = { creoAppVersion: appVersion, appProvenance: 'creo-v1-summarize', email: 'global.user.adauris@gmail.com', uid };

        // * always add appVersion to posthog events
        if (properties) {
            properties = { ...properties, ...payloadConst };
        } else {
            properties = payloadConst;
        }

        payload = {
            event,
            properties,
            distinctId
        };

        const reqOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };

        const response = await fetch(REGISTER_EVENT_API_URL, reqOptions);
        if (!response.ok || response.status != 200) {
            throw new Error(`Error registering event to Posthog|${response.status}|${response.statusText}`);
        }
        return true;
    } catch (error: unknown) {
        console.log(error);
        const { message, cause } = formatSentryError('Error while attempting to log event to Posthog', 'registerPosthogEvent', error);
        captureException(new Error(message, cause));
        return false;
    }
};
