import { Button } from '@chakra-ui/react';
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import { MdReplay10, MdForward30 } from 'react-icons/md';
import { Dispatch, SetStateAction } from 'react';
import { NarrationInterface } from '../../types/types';
import { useGlobalNarrationContext } from '../../context/globalNarration';
import { registerPosthogEvent } from '../../helpers/posthogEventHelpers';
import { EVENT_NAME } from '../../constants/posthogConstants';
import { formatSentryError } from '../../helpers/sentryHelpers';
import { captureException } from '@sentry/nextjs';

interface AudioPlayerConfigProps {
    speed: number;
    setSpeed: Dispatch<SetStateAction<number>>;
    toggleSpeed: ({ speed, setSpeed }: any) => void;
}

export const AudioPlayerConfig = ({ speed, setSpeed, toggleSpeed }: AudioPlayerConfigProps) => {
    const { setPlaying, playerRef, onEnded, selectedNarration, distinctId } = useGlobalNarrationContext();

    const onPlay = () => {
        setPlaying(true);
        registerPosthogEvent(EVENT_NAME.play, distinctId, {
            title: selectedNarration?.title,
            siteName: selectedNarration?.siteName,
            url: selectedNarration?.sourceUrl,
            audioUrl: selectedNarration?.audio,
            sourceType: selectedNarration?.sourceType,
            userId: selectedNarration?.userId,
            currentLocation: window.location.href
        });
    };

    const onPause = () => {
        setPlaying(false);
        registerPosthogEvent(EVENT_NAME.pause, distinctId, {
            title: selectedNarration?.title,
            siteName: selectedNarration?.siteName,
            url: selectedNarration?.sourceUrl,
            audioUrl: selectedNarration?.audio,
            sourceType: selectedNarration?.sourceType,
            userId: selectedNarration?.userId,
            currentLocation: window.location.href
        });
    };

    const onForward = () => {
        registerPosthogEvent(EVENT_NAME.goForwardSeconds, distinctId, {
            time: 30,
            unit: 's',
            title: selectedNarration?.title,
            siteName: selectedNarration?.siteName,
            url: selectedNarration?.sourceUrl,
            audioUrl: selectedNarration?.audio,
            currentLocation: window.location.href
        });
    };

    const onRewind = () => {
        registerPosthogEvent(EVENT_NAME.goBackSeconds, distinctId, {
            time: 10,
            unit: 's',
            title: selectedNarration?.title,
            siteName: selectedNarration?.siteName,
            url: selectedNarration?.sourceUrl,
            audioUrl: selectedNarration?.audio,
            currentLocation: window.location.href
        });
    };

    const captureAudioPlayerIssue = (eventType: string) => {
        const { message, cause } = formatSentryError(
            `Audio player ${eventType} event triggered for narration ${selectedNarration?.audio}`,
            'AudioPlayerConfig',
            playerRef.current.audio.current.error
        );
        captureException(new Error(message, cause));
    };

    const handleEnd = (narration: NarrationInterface) => {
        registerPosthogEvent(EVENT_NAME.ended, distinctId, {
            title: narration.title,
            siteName: narration.siteName,
            url: narration.sourceUrl,
            audioUrl: narration.audio,
            currentLocation: window.location.href
        });
        onEnded();
        console.log('ended');
    };

    const SpeedButton = (
        <Button
            key="key"
            m="0"
            h="8"
            variant="outline"
            colorScheme="currentColor"
            position="absolute"
            right={0}
            marginRight={9}
            onClick={() => {
                toggleSpeed({ speed, setSpeed });
            }}
        >
            {speed}x
        </Button>
    );
    if (selectedNarration && selectedNarration.audio) {
        return (
            <AudioPlayer
                src={selectedNarration.audio}
                ref={playerRef}
                onPlay={onPlay}
                onPause={onPause}
                onEnded={() => handleEnd(selectedNarration)}
                onError={() => captureAudioPlayerIssue('onError')}
                onStalled={() => captureAudioPlayerIssue('onStalled')}
                customControlsSection={[SpeedButton, RHAP_UI.MAIN_CONTROLS]}
                customProgressBarSection={[RHAP_UI.MAIN_CONTROLS, RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION]}
                customIcons={{
                    forward: <MdForward30 data-cy="audio-player-forward" onClick={onForward} />,
                    rewind: <MdReplay10 data-cy="audio-player-rewind" onClick={onRewind} />
                }}
                progressJumpSteps={{
                    forward: 30000,
                    backward: 10000
                }}
            />
        );
    } else {
        return <></>;
    }
};
