import { getPlayAppLink } from '../helpers/utmHelpers';
import { NarrationInterface } from '../types/types';

const HARD_CODED_NARRATION_SETTINGS = {
    creationDate: {
        seconds: 1664921243,
        nanoseconds: 35000000,
        toDate: () => new Date(),
        toMillis: () => 123,
        isEqual: () => true,
        valueOf: () => 'asdf'
    },
    userId: 'Ad-Auris',
    narrationMethod: 'instantUrl',
    sourceType: 'article',
    sourceLanguage: 'en',
    category: 'demo',
    publishDate: {
        _seconds: 1664921243,
        _nanoseconds: 35000000
    },
    version: 1,
    modifiedDate: {
        seconds: 1664921243,
        nanoseconds: 35000000,
        toDate: () => new Date(),
        toMillis: () => 123,
        isEqual: () => true,
        valueOf: () => 'asdf'
    },
    narrationSettings: {
        titleVoice: 'en-CA-LiamNeural',
        bodyVoice: 'en-US-JennyNeural'
    },
    podcastChannelId: '0'
};

export const PLAY_APP_LINK = 'https://play.ad-auris.com';

export const DEFAULT_LIBRARY_NARRATION: NarrationInterface = {
    ...HARD_CODED_NARRATION_SETTINGS,
    id: '1',
    title: 'Welcome to Ad Auris',
    siteName: 'Ad Auris',
    image: 'https://storage.googleapis.com/ad-auris-django-bucket/media/Ad%20Auris%20Logo%20Assets-01.png',
    sourceUrl: `${PLAY_APP_LINK}?utm_source=creo&utm_medium=mirco-app&utm_campaign=summarize&utm_content=source-url-on-narration`,
    audio: 'https://storage.googleapis.com/ad-auris-mvp-bucket/data-dashboard-narrations/HowToGetTheMostOfAdAuris.mp3'
};
