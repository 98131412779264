import { Dispatch, ReactNode, SetStateAction } from 'react';
import firestore from '@firebase/firestore-types';

export type FirebaseTokenType = string;
export type UserNameType = string;
export type OrgNameType = string;
export type EmailType = string;
export type JoinCodeType = string;
export type EncryptedOrgIdType = string;
export type EncryptedUserIdType = string;
export type UserIdType = string;
export type ViewIdType = string;
export type RoleType = string;
export type UIdType = string;
export type RouteIdentifierType = string;
export type RssCounterType = number;
export type TierType = string;
export type RssActiveType = boolean;
export type PodcastActiveType = boolean;
export type InstantCounterType = number;
export type CustomLimitType = number;
export type CustomCounterType = number;
export type LegacyCustomActiveType = boolean;
export type RssLimitType = string;
export type InstantLimitType = number;
export type MusicType = string;
export type NarrationMethodType = string;
export type NewNarrationType = string;
export type ApiKeyType = string;
export type AuthDomainType = string;
export type DatabaseURLType = string;
export type ProjectIdType = string;
export type StorageBucketType = string;
export type MessagingSenderIdType = string;
export type AppIdType = string;
export type MeasurementIdType = string;
export type UrlType = string;
export type NarrationIdType = number;
export type NarrationTitleType = string;
export type NarrationSiteNameType = string;
export type NarrationImageType = string;
export type NarrationDateCreatedType = string;
export type NarrationMusicType = string;
export type DeletionResponse = any;
export type ArticleTitleType = string;
export type SignupProvenanceType = string;
export type ListenedType = boolean | null;
export type PodcastPrivacyType = 'private' | 'public';
export type PodcastStatusType = 'off' | 'pending' | 'activated' | 'self-serve';
export type IsLoggedType = boolean | null;
export type ArticleDescriptionType = string;
export type PodcastActivationTimeType = string;
// Default firestore alphanumeric string
export type FirestoreTimestampType = firestore.Timestamp;
export type FirestoreDocumentIdType = string;
export type DocumentVersionType = number;
export type UTCEpochDateType = string;
export type LanguageCodeType = string;
export type VoiceCodeType = string;
export type narrationErrorMapType = Record<string, NarrationErrorObjInterface>;

export interface SignupInterface {
    token: FirebaseTokenType;
    username: UserNameType;
    orgName: OrgNameType;
    email: EmailType;
    joinCode?: JoinCodeType;
    signupProvenance: SignupProvenanceType;
}

export interface NewUserInterface {
    organisationId: EncryptedOrgIdType;
    id: EncryptedUserIdType;
    companyId: EncryptedOrgIdType;
    username: UserNameType;
    email: EmailType;
    viewId: ViewIdType;
    filteredViewId: ViewIdType;
    joinCode: JoinCodeType;
    role: RoleType;
    organisation: OrgNameType;
}

export interface UserInterface {
    id: EncryptedUserIdType;
    uid: UIdType;
    organisationId: EncryptedOrgIdType;
    username: UserNameType;
    email: EmailType;
    viewId: ViewIdType;
    filteredViewId: ViewIdType;
    routeIdentifier: RouteIdentifierType;
    role: RoleType;
    joinCode: JoinCodeType;
    organisation: OrgNameType;
    orgAdminId: UIdType;
    subscriptionData?: {
        rss_counter: RssCounterType;
        tier: TierType;
        rss_active: RssActiveType;
        podcast_active: PodcastActiveType;
        instant_counter: InstantCounterType;
        custom_limit: CustomLimitType;
        custom_counter: CustomCounterType;
        legacy_custom_active: LegacyCustomActiveType;
        rss_limit: RssLimitType;
        instant_limit: InstantLimitType;
    };
    token?: FirebaseTokenType;
}

export interface UrlNarrationInterface {
    url: UrlType;
    id: EncryptedUserIdType;
    user: any;
    narrationMethod: NarrationMethodType;
    music: NarrationMusicType;
    narrationType: NewNarrationType;
    titleVoice: NarrationTitleType;
}

export interface TextNarrationInterface {
    user: any;
    text: string;
    title: string;
    narrationMethod: NarrationMethodType;
    music: NarrationMusicType;
    titleVoice: NarrationTitleType;
}

export interface FirebaseConfig<T> {
    apiKey: ApiKeyType | T;
    authDomain: AuthDomainType | T;
    databaseURL: DatabaseURLType | T;
    projectId: ProjectIdType | T;
    storageBucket: StorageBucketType | T;
    messagingSenderId: MessagingSenderIdType | T;
    appId: AppIdType | T;
    measurementId: MeasurementIdType | T;
}

export interface NarrationSettingsInterface {
    titleVoice: VoiceCodeType | null;
    bodyVoice: VoiceCodeType | null;
}

export interface PublishDateInterface {
    _seconds: number;
    _nanoseconds: number;
}

export enum StatusEnum {
    Success = 'SUCCESS',
    Failure = 'FAILURE',
    Pending = 'PENDING',
    Debug = 'DEBUG'
}

export interface NarrationInterface {
    id: FirestoreDocumentIdType;
    userId: string;
    creationDate: FirestoreTimestampType;
    modifiedDate: FirestoreTimestampType;
    title: string | null;
    audio: UrlType | null;
    narrationSettings: NarrationSettingsInterface;
    narrationMethod: string;
    // URL is article URL, uploaded PDF, text bucket URL
    sourceUrl: UrlType;
    // type is article, doc, pdf, file, scanned, etc
    sourceType: UrlType;
    siteName: string | null;
    image: UrlType | null;
    sourceLanguage: LanguageCodeType | null;
    category: string | null;
    publishDate: PublishDateInterface;
    version: DocumentVersionType;
    podcastChannelId: FirestoreDocumentIdType;
    status?: StatusEnum;
}

export interface ParsedCookieInterface {
    token: FirebaseTokenType;
    orgRouteIdentifier: RouteIdentifierType;
    userId: UserIdType;
}

export interface GetNarrationRequestInterface {
    orgRouteIdentifier: RouteIdentifierType;
    userId: UserIdType;
    limit: number;
    offset: number;
}

export interface GetNarrationCountInterface {
    orgRouteIdentifier: RouteIdentifierType;
    userId: UserIdType;
    listenedFilter: number | null;
}

export interface AudioPlayerProps {
    article_url: UrlType;
    org_name: OrgNameType;
    image_url?: UrlType;
    organisation_image?: UrlType;
    article_title: ArticleTitleType;
    url: UrlType;
}

export interface NewNarrationCreated {
    fileUrl: string;
    ttsId: number;
    widgetUrl: string;
    route_identifier: string;
    org_identifier: string;
}

export interface PageProps {
    setShowPlayer: Function;
}

// export interface AuthContextInterface {
//     user: User | null;
//     loading: boolean;
//     signOut: Function;
//     postLogin: Function;
// }

export interface GlobalNarrationContextInterface {
    playing: boolean;
    setPlaying: Dispatch<SetStateAction<boolean>>;
    onEnded: Function;
    setOnEnded: Dispatch<SetStateAction<Function>>;
    selectedNarration: NarrationInterface | null;
    setSelectedNarration: Dispatch<SetStateAction<NarrationInterface | null>>;
    playerRef: any;
    playPause: Function;
    distinctId: string;
    setDistinctId: Dispatch<SetStateAction<string>>;
}

export interface NewPodcastPayload {
    organisationId: Number;
    title: string;
    description: string;
    email: string;
    cover_art: string;
    author: string;
    copyright: string;
    language: string;
    category_1: string;
    category_2: string;
    explicit: string;
    site_url: string;
}

export interface PodcastMetadataInterface {
    name: string;
    image: string;
    link: string;
    activationTime: string;
    syncTime: string;
    privacy: PodcastPrivacyType;
    comingSoon: boolean;
    listenOnImage: string;
    icon: string;
    color?: string;
    tutorialVideoSrc?: string;
    tutorialVideoSrcMobile?: string;
    doneButtonText?: string;
}

export interface PodcastDistributionInterface {
    id: string;
    podcasts_id: string;
    podcast_link_spotify: string | null;
    podcast_link_apple: string | null;
    podcast_link_google: string | null;
    is_activated_spotify: PodcastStatusType;
    is_activated_apple: PodcastStatusType;
    is_activated_google: PodcastStatusType;
}

export interface PodcastServerSidePropsInterface {
    podcastDistribution: PodcastDistributionInterface;
    dashboardUser: UserInterface;
}

export interface PodcastDistributionPropsInterface {
    podcastDistribution: PodcastDistributionInterface;
    dashboardUser?: UserInterface;
}

export interface NarrationCategoriesInterface {
    name: string;
    narrations: NarrationInterface[];
}

export enum FEATURE_FLAG {
    LANDING_PAGE = 'play-app-landing-page'
}

export enum LandingPageVersion {
    a = 'a',
    b = 'b'
}

export interface SplashCardInfoInterface {
    imageSrc: string;
    caption: string;
}

export interface UTMInterface {
    source: string | null;
    medium: string | null;
    campaign: string | null;
    content: string | null;
}

export interface UTMQueryInterface {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_content?: string;
}

export interface ChromeExtCheckInterface {
    hasChromeExtensionNarrations: boolean | undefined;
    status: string;
    error: string;
}
export interface ChromeExtCheckAPIResponse {
    hasChromeExtensionNarrations: boolean | undefined;
}

export interface PublisherInfoInterface {
    publicationName: string;
    imageSrc: string;
    description: string;
    subscriptionLink: string;
    useCategories?: boolean; // used for orgs that have multiple categories/feeds
    authRequired?: boolean;
}

export interface NarrationErrorObjInterface {
    message: string;
    checkFunc: (url: string) => boolean;
    comingSoon: boolean;
    name: string;
    internalLink?: string;
}

export interface BaseChildrenProps {
    children: ReactNode;
}

export enum APP_ENV {
    development = 'development',
    test = 'test',
    staging = 'staging',
    production = 'production'
}
