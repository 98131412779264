import { APP_ENV } from '../types/types';

export const getGlobalUid = (environmentVar?: APP_ENV) => {
    const appEnvironment = environmentVar || process.env.APP_ENV;
    // * UIDs from global.user.adauris@gmail.com
    const stagingUID = 'lF1KF0n1tYTVQlc0WiWunPxxSrz2';
    const prodUID = 'YMoqySYuk6cYdSZezUKjNKC04w52';

    let uid = '';

    switch (appEnvironment) {
        case APP_ENV.development:
            uid = stagingUID;
            break;
        case APP_ENV.test:
            uid = stagingUID;
            break;
        case APP_ENV.staging:
            uid = stagingUID;
            break;
        case APP_ENV.production:
            uid = prodUID;
            break;
        default:
            uid = prodUID;
    }
    return uid;
};
