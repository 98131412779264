import { FormErrorMessage, Text } from '@chakra-ui/react';
import { NarrationErrorObjInterface } from '../../../types/types';

interface NarrationUrlErrorMessageProps {
    errObj: NarrationErrorObjInterface;
}

export const NarrationUrlErrorMessage = ({ errObj }: NarrationUrlErrorMessageProps) => {
    return (
        <FormErrorMessage color="darkred">
            <Text m="auto">{errObj?.message}</Text>
        </FormErrorMessage>
    );
};
