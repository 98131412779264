import axios, { AxiosError, AxiosResponse } from 'axios';
import { sendNotificationToAda } from './slackHelpers';
import {
    NarrationInterface,
    FirestoreDocumentIdType,
    UrlNarrationInterface,
    narrationErrorMapType,
    NarrationErrorObjInterface
} from '../types/types';
import { isAdAurisLink, isDoi, isEbook, isGoogleDoc, isMissingHttp, isPdf, isPodcastLink, isUrl } from './urlHelpers';
import { EVENT_NAME } from '../constants/posthogConstants';
import { registerPosthogEvent } from './posthogEventHelpers';
import { captureException } from '@sentry/nextjs';
import { formatSentryError } from './sentryHelpers';

export const createUrlNarration = async (payload: UrlNarrationInterface): Promise<FirestoreDocumentIdType> => {
    try {
        const isValidUrl = isUrl(payload.url);

        if (!isValidUrl) {
            throw new Error(`url is not valid | url: ${payload.url}`); // sentry logs in parent catch
        }

        const timeout = Number(process.env.CREATE_NARRATION_TIMEOUT) || 350000;
        const response: AxiosResponse = await axios.post(
            `${process.env.PLAY_NARRATION_CREATION_SERVICE}/v2/audio/article-summarization-to-audio`,
            payload,
            {
                timeout
            }
        );

        let responseData = response.data;

        if (!responseData || !responseData.narrationId) {
            throw Error('No narrationId returned for new narration');
        }

        const newNarrationId: FirestoreDocumentIdType = responseData.narrationId;

        return newNarrationId;
    } catch (error: unknown | AxiosError) {
        const { message, cause } = formatSentryError('Error creating narration', 'createUrlNarration', error);
        captureException(new Error(message, cause));
        throw error;
    }
};

export const createSummaryNarration = async (payload: UrlNarrationInterface): Promise<FirestoreDocumentIdType> => {
    try {
        const responseData = await axios.post(`/api/v1/narrations/createSummaryNarration`, payload);

        const newNarrationId: FirestoreDocumentIdType = responseData.data;

        return newNarrationId;
    } catch (error: unknown | AxiosError) {
        throw error;
    }
};

export const getNarrationById = async (narrationId: FirestoreDocumentIdType) => {
    const { data, status } = await axios.get(`/api/v1/narrations/getNarration/${narrationId}`);
    if (status !== 200) {
        throw Error;
    }
    const newNarration = data as NarrationInterface;
    return newNarration;
};

export const SHARE_PAGE_ERROR_MSG = 'There was an error retrieving this narration, please make sure you copied the link properly and try again';

export const CREATE_NARRATION_BTN_TEXT = 'Generate Audio';

export const GOOGLE_DOC_ERROR_MESSAGE = 'Google docs are incompatible. Try a URL from a different publication.';

export const PDF_ERROR_MESSAGE = 'PDF files are incompatible. Try a URL from a different publication.';

export const EBOOK_ERROR_MESSAGE = 'EBooks are incompatible. Try a URL from a different publication.';

export const HTTP_ERROR_MESSAGE = 'URL is incomplete, missing "http://" or “https://”.';

export const DOI_ERROR_MESSAGE = 'Official journals and papers with a doi URL are incompatible. Try a URL from a different publication.';

export const PODCAST_LINK_ERROR_MESSAGE = 'Cannot narrate a podcast RSS feed. Looking to set-up your podcast?';

export const AD_AURIS_LINK_ERROR_MESSAGE =
    "Cannot narrate an Ad Auris narration. If you want to add this narration to your playlist, please input the original article's URL.";

export const CHAR_LIMIT_ERROR_MESSAGE = 'Too many characters';

export const NARRATION_ERROR_MAP: narrationErrorMapType = {
    googleDoc: {
        message: GOOGLE_DOC_ERROR_MESSAGE,
        checkFunc: isGoogleDoc,
        comingSoon: true,
        name: 'Google Docs'
    },
    pdf: {
        message: PDF_ERROR_MESSAGE,
        checkFunc: isPdf,
        comingSoon: true,
        name: 'PDF'
    },
    ebook: {
        message: EBOOK_ERROR_MESSAGE,
        checkFunc: isEbook,
        comingSoon: false,
        name: 'EBook'
    },
    doi: {
        message: DOI_ERROR_MESSAGE,
        checkFunc: isDoi,
        comingSoon: true,
        name: 'DOI'
    },
    isPodcastLink: {
        message: PODCAST_LINK_ERROR_MESSAGE,
        checkFunc: isPodcastLink,
        internalLink: '/podcasts',
        comingSoon: false,
        name: 'Podcast Link'
    },
    isAdAurisLink: {
        message: AD_AURIS_LINK_ERROR_MESSAGE,
        checkFunc: isAdAurisLink,
        comingSoon: false,
        name: 'Ad Auris Narration'
    },
    http: {
        message: HTTP_ERROR_MESSAGE,
        checkFunc: isMissingHttp,
        comingSoon: false,
        name: 'Missing Http'
    }
};

export const checkIsError = (newValue: string, distinctId: string): NarrationErrorObjInterface | null => {
    if (newValue) {
        let check: keyof narrationErrorMapType;
        for (check in NARRATION_ERROR_MAP) {
            const errObj = NARRATION_ERROR_MAP[check];
            if (errObj.checkFunc(newValue)) {
                registerPosthogEvent(EVENT_NAME.textInput, distinctId, {
                    success: false,
                    textInput: {
                        name: 'Article URL'
                    },
                    error: {
                        reason: check,
                        clientMessage: errObj.message
                    }
                });
                return errObj;
            }
        }
    }
    return null;
};
