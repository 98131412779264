import { IconButton, IconButtonProps, useBreakpointValue, Tooltip } from '@chakra-ui/react';
import React from 'react';

export const NarrationCardIconButton = (props: IconButtonProps) => {
    const size = useBreakpointValue({ base: 'xs', sm: 'md' });

    return (
        <Tooltip hasArrow label={props['aria-label']}>
            <IconButton variant={'ghost'} size={size} isRound {...props} />
        </Tooltip>
    );
};
