import React, { useMemo } from 'react';
import { BsLink45Deg } from 'react-icons/bs';
import { useToast } from '@chakra-ui/react';
import { NarrationCardIconButton } from './NarrationCardButton';
import { NarrationInterface } from '../../types/types';
import { initializeBaseAppUrl } from '../../helpers/urlHelpers';
import { getPlayAppLink } from '../../helpers/utmHelpers';

interface ShareButtonProps {
    narration: NarrationInterface;
}
const ShareLinkButton = ({ narration }: ShareButtonProps): JSX.Element => {
    const toast = useToast();

    const localSharableNarrationUrl: string = useMemo(() => {
        const localBaseAppUrl = initializeBaseAppUrl();
        const narrationTitle = narration.title ? narration.title.replaceAll(' ', '-').toLowerCase() : '';
        const url = getPlayAppLink({ utm_content: 'share-button' }, `${localBaseAppUrl}/narration/v1/${narrationTitle}/${narration.id}`);
        return url;
    }, [narration]);

    const onButtonClick = () => {
        copyToClipBoard();
    };

    const copyToClipBoard = () => {
        toast({
            title: 'URL Copied!',
            description: 'Your Narration URL has been copied to clipboard',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top'
        });
        navigator.clipboard.writeText(localSharableNarrationUrl);
        console.log('set flag, clipboarded, and Clicked!');
    };
    return (
        <NarrationCardIconButton
            fontSize={{ base: '1em', sm: '1.25em' }}
            color="brand.500"
            icon={<BsLink45Deg />}
            aria-label="Copy narration URL"
            onClick={onButtonClick}
        />
    );
};

export default ShareLinkButton;
