import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next';
import { useEffect, useState } from 'react';
import GenerateSummarization from './create-summarization/GenerateSummarization/GenerateSummarization';
import { Box, Flex, chakra, Container, Stack, Button, Tooltip, FormLabel, Select } from '@chakra-ui/react';
import { AdAurisLogo } from '../components/Buttons/AdAurisLogo';
import { useGlobalNarrationContext } from '../context/globalNarration';
import { HeaderCard } from '../components/Cards/HeaderCard';
import { LoadingCard } from '../components/Cards/LoadingCard';
import { SuccessfulNarrationView } from '../components/Cards/SuccessfulNarrationView';
import CustomAudioPlayer from '../components/AudioPlayer/CustomAudioPlayer';
import Footer from '../components/Footers/Footer';
import { ErrorCard } from '../components/Cards/ErrorCard';
import dynamic from 'next/dynamic';
import { TsParticlesConfigOptions } from '../components/Animations/TsParticles/TsParticlesConfigOptions';
import { v4 as uuidv4 } from 'uuid';
import { registerPosthogEvent } from '../helpers/posthogEventHelpers';
import { EVENT_NAME } from '../constants/posthogConstants';
import { parseUtmCodes } from '../helpers/utmHelpers';
import { UTMInterface } from '../types/types';
import SEOHead from '../components/SEO/SEOHead';
import globalMeta from '../components/SEO/globalMeta';

export const getServerSideProps: GetServerSideProps = async (context) => {
    const utmParams = parseUtmCodes(context.query);

    return {
        props: {
            utmParams
        }
    };
};

const CreateSummarizationPage: NextPage = ({ utmParams }: InferGetServerSidePropsType<typeof getServerSideProps>) => {
    const { selectedNarration, setDistinctId } = useGlobalNarrationContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const uuid = uuidv4();
        setDistinctId(uuid);

        // * set type after it was inferred from serverSideProps
        const typedUtmParams = utmParams as UTMInterface;

        registerPosthogEvent(EVENT_NAME.pageLoad, uuid, {
            pageName: 'creo-summarize-landing-page',
            utm_source: typedUtmParams.source,
            utm_medium: typedUtmParams.medium,
            utm_campaign: typedUtmParams.campaign,
            utm_content: typedUtmParams.content
        });
    }, []);

    useEffect(() => {
        if (!selectedNarration) {
            setIsError(false);
        }
    }, [selectedNarration]);

    // * https://blog.bitsrc.io/using-non-ssr-friendly-components-with-next-js-916f38e8992c
    // this implementation was needed as the underlying animation packages break with Server side rendering, Next provides a method to turn off ssr and this allows for the component to access the global window variable which is needed
    const TsParticles_NO_SSR = dynamic(() => import('../components/Animations/TsParticles/TsParticles'), { ssr: false });

    // https://www.ohmycrawl.com/nextjs-head/
    const structuredLd = JSON.stringify({
        //@ts-ignore
        '@context': globalMeta.canonicalUrl,
        description: '30 second article summaries in audio'
    });

    return (
        <>
            <SEOHead
                canonicalUrl={globalMeta.siteUrl}
                structuredData={structuredLd}
                title="Summarize"
                description="30 second article summaries in audio."
                ogType="website"
            />
            <Box minH="100vh" w="100vw">
                <TsParticles_NO_SSR config={TsParticlesConfigOptions.spiderWebMouse} />

                <Flex w={'100%'} m="auto" justifyContent={'center'} alignItems="center" direction="column">
                    <Box position={'absolute'} top={'10px'} left={'10px'}>
                        <AdAurisLogo />
                    </Box>
                    <Container w="fit-content" maxW={{ base: 'xl', md: '3xl' }} pt={{ base: 20 }}>
                        <Stack bg={'transparent'} rounded={'xl'} p={{ base: 4, sm: 6, md: 8 }} spacing={{ base: 8 }} alignItems="center">
                            <HeaderCard />

                            {isLoading ? (
                                <LoadingCard />
                            ) : isError ? (
                                <ErrorCard setIsError={setIsError} />
                            ) : selectedNarration === null ? (
                                <GenerateSummarization setIsLoading={setIsLoading} setIsError={setIsError} />
                            ) : (
                                <SuccessfulNarrationView narration={selectedNarration} />
                            )}
                        </Stack>
                    </Container>
                    <Footer />
                </Flex>
            </Box>
            <CustomAudioPlayer />
        </>
    );
};

export default CreateSummarizationPage;
