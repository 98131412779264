import { Box, Heading, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { PongSpinner, WaveSpinner, CubeSpinner, BallSpinner, HoopSpinner } from 'react-spinners-kit';

// removed from spinners because data is returned too fast to see them all
/*

    { text: "You're not beating me this time AI!", loader: <PongSpinner color="#00ff89" /> },
    { text: "Oops you weren't supposed to see that...", loader: <BallSpinner color="#00ff89" /> },
    */

const loaderData = [
    { text: "We're obtaining the text from your URL", loader: <CubeSpinner /> },
    { text: 'Our AI is summarizing your content', loader: <HoopSpinner color="#00ff89" /> },
    {
        text: 'Our robots are narrating your summary. It will be available shortly!',
        loader: <WaveSpinner color="#00ff89" />
    }
];

export const LoadingCard = () => {
    const [loaderNumber, setLoaderNumber] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (loaderNumber < loaderData.length - 1) setLoaderNumber(loaderNumber + 1);
        }, 3000);
    }, [loaderNumber]);

    return (
        <>
            <Heading size="sm" textAlign={'center'}>
                {loaderData[loaderNumber].text}
            </Heading>
            {loaderData[loaderNumber].loader}
        </>
    );
};
